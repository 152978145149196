import { isMatch } from 'lodash';
import { ServiceProvider } from '@lambda/commons/apis/elasticsearch/types';

interface ServiceProviderVariants {
  cellularStatus: string;
  cellularNetwork?: string;
  networkProvider?: string;
}

const unlockedCondition: ServiceProviderVariants = {
  cellularStatus: 'Unlocked',
  cellularNetwork: 'CDMA',
};

const SERVICE_PROVIDER_CONDITION_MAPPING: {
  [serviceProvider in ServiceProvider]: Array<ServiceProviderVariants>;
} = {
  Unlocked: [unlockedCondition],
  'AT&T': [
    unlockedCondition,
    {
      cellularStatus: 'Unlocked',
      cellularNetwork: 'GSM',
    },
    {
      cellularStatus: 'Locked',
      networkProvider: 'AT&T',
    },
  ],
  'T-Mobile': [
    unlockedCondition,
    {
      cellularStatus: 'Unlocked',
      cellularNetwork: 'GSM',
    },
    {
      cellularStatus: 'Locked',
      networkProvider: 'T-Mobile',
    },
  ],
  Verizon: [
    unlockedCondition,
    {
      cellularStatus: 'Locked',
      networkProvider: 'Verizon',
    },
  ],
  Sprint: [
    unlockedCondition,
    {
      cellularStatus: 'Locked',
      networkProvider: 'Sprint',
    },
  ],
};

export const convertCellularFieldsToServiceProvider = (
  extractedTags: Partial<ServiceProviderVariants>,
): Array<ServiceProvider> =>
  Object.keys(SERVICE_PROVIDER_CONDITION_MAPPING)
    .map((serviceProvider) => {
      const conditions =
        SERVICE_PROVIDER_CONDITION_MAPPING[serviceProvider as ServiceProvider];

      if (conditions.some((condition) => isMatch(extractedTags, condition)))
        return serviceProvider;

      return undefined;
    })
    .filter(Boolean) as Array<ServiceProvider>;
