import { RC_DURATION } from '@lambda/reebelo';
import auWarrantyPricing from './pricing/AU_LIMITED.json'; // Revert on Dec 8
import usWarrantyPricing from './pricing/US.json';
import us13WarrantyPricing from './pricing/US_13.json';
import dev13WarrantyPricing from './pricing/DEV_13.json';
import dev12WarrantyPricing from './pricing/DEV_12.json';
import nzWarrantyPricing from './pricing/NZ.json';
import sgWarrantyPricing from './pricing/SG.json';
import caWarrantyPricing from './pricing/CA.json';
import ca12WarrantyPricing from './pricing/CA_12.json';
import devWarrantyPricing from './pricing/DEV.json';

export type WarrantyItemPrice = {
  from: number;
  to: number;
  price: number;
  variantId: number;
};

const generateWarrantyPricing = () => {
  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au')
    return { 24: auWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us')
    return { 24: usWarrantyPricing, 13: us13WarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz')
    return { 24: nzWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'quista')
    return { 24: sgWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-ca')
    return { 24: caWarrantyPricing, 12: ca12WarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev') {
    return {
      24: devWarrantyPricing,
      13: dev13WarrantyPricing,
      12: dev12WarrantyPricing,
    };
  }

  return {};
};

const warrantyPricing = generateWarrantyPricing();

export default warrantyPricing as Partial<
  Record<RC_DURATION, Record<string, Array<WarrantyItemPrice>>>
>;
